<template>
	
	
    <el-dialog
      title="患者病例详情"
      :visible.sync="dialogVisible"
      width="1000px"
    >
	
    <div class="weekBtn" v-html="content" > 
		
    </div>
	
	<br/>
	<br/>
	<br/>	
	<div v-if="showInvoice" style="border:1px solid #66b1ff;padding: 20px; width: 30%; text-align: center;">
		开票信息
		<br/> 
		<br/> 
		发票类型：<input  type='radio' name='ABC'  v-model='typeInvoice'  value="1" />纸制发票

				<input  type='radio' name='ABC'   v-model='typeInvoice'  value="0" />电子发票 

		<br/> 
		<br/> 
		发票内容：<input disabled type="text" name='2fp' v-model="contentInvoice" />
		<br/>
		<br/> 
		抬头类型：<input disabled type="text" name='fp'  v-model="type" />
		<br/>
		<br/> 
		发票金额：<input disabled type="text" name='4fp'  v-model="price" />
		<br/>
		<br/> 
		发票抬头：<input   type="text" name='fp'   v-model="taitou" />
		<br/>
		<br/> 
		家庭地址：<input   type="text" name='444fp'    v-model="address"   />
		<br/>
		<br/> 
		
		<el-button type="primary"  @click="setDownInvoice">提交申请</el-button> 
		
		
		
		
		
	</div>
	
	
	<span slot="footer" class="dialog-footer">
		<el-button type="primary" @click="openDownInvoice">申请发票</el-button> 
		<el-button type="primary" @click="downPdf">下载pdf</el-button>  
	</span>
	
  
  </el-dialog>
</template>

<script>
	import axios from "axios";
	import { VueEditor } from "vue2-editor";
  export default {
    name: "ChatRoomAddCaseRecordPatient",
    props: ['info'],
    data() {
      return {
		  typeInvoice:0,
		  contentInvoice:'病情咨询',
		  taitou:sessionStorage.getItem('uname'),
		  price:0,
		  address:'', 
		  type:'个人',
		  case_id:0,
		  
		  address2:'',
		  
		  
		  showInvoice:0,
		  abc:0,
		content:'sfdddddd',
        dialogVisible: false,
		id:0,
		textarea:'',
		finfo:{}
      }
    },
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    },

	methods: {
		
		//提交发票信息
		setDownInvoice(){
			
			const params = new URLSearchParams();
			params.append('case_id',this.case_id);   
			params.append('typeInvoice', this.typeInvoice); 
			params.append('contentInvoice', this.contentInvoice); 
			params.append('taitou', this.taitou); 
			params.append('price', this.price); 
			params.append('address', this.address); 
			params.append('type', this.type); 
			
			
			axios.post(this.apiUrl+'/api/user/setDownInvoice', params)
			.then((response) => { 	 
	                this.$message.success('申请成功');
			  })
			  .catch(function(error) {
				console.log(error);
			  });	
		},
		openDownInvoice(){ 
			
			this.showInvoice = 1;
		},
		
		downPdf(){ 
			 
			window.open(this.apiUrl+'/api/doctor/downPdf?id='+this.id);
			
			
		},
		//初始化信息
		open(id) {
			this.id = id
			this.dialogVisible = true
			
			const params = new URLSearchParams();
			params.append('id',id); 
					  
					 
			params.append('content', this.textarea);  
			
			axios.post(this.apiUrl+'/api/user/getChatRoomAddCaseRecordPatientInfo', params)
			.then((response) => { 	 
							this.content =  response.data.data.content; 
							this.price =  response.data.data.price; 
							this.case_id =  response.data.data.id; 
			  })
			  .catch(function(error) {
				console.log(error);
			  }); 
		  
		  
	    },
	  
	   
	 
	    created() {  
	  	  
	  	  
		} 
	  
	  
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
